@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
.Circular-Progress {
  text-align: center;
  margin-top: 15%;
}
.card-border {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }
  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 6rem;
  --fg: var(--valueColor);
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      white 80%,
      transparent 0 99.9%,
      white 0
    ),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

.capitalized {
  text-transform: capitalize!important;
}
.pointer{
  cursor: pointer!important;
}
.border-left{
  border-left: 1px solid #e0e0e0;
}
.view-icon{
  margin-left: 10px;
  margin-top: -3px;
}

.hide-plus-button{
  display: none;
}
div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
}
.pieChart:hover {
  transform: translate(0%, 1%);
  transform: scale(1.05);
}
.positioning-menu {
  position: absolute;
  top: -10%;
  left: 8.5%;
}
.custom-popover-details {
  position: absolute;
  top: -10%;
  left: -15%;
}
.fixedWidth {
  width: 30%;
  margin-right: 18px;
}
.align-center{
  text-align: center!important;
}
.mx-auto {
  width: 70%;
  overflow: auto;
}
.pointer {
  cursor: pointer;
}
.no-scroll::-webkit-scrollbar {
  display: none;
}
.nowrap{
  text-wrap: nowrap!important;
}
.set-image-width {
  max-width: 100%;
}
.width-lable {
  min-width: 20%;
}
/* @keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}
.circle-wrap .inside-circle {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background: #d2eaf1;
  line-height: 120px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  color: #1e51dc;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
} */

.payroll-header input{
  font-weight: bold!important;
}

.payroll-header{
  font-weight: bold!important;
}
#outlined-basic-payroll-header{
  font-weight: bold!important;
}

.pdf .is-gapless {
  display: none!important;
}
.pdf canvas {
  width: 90%!important;
}
.pdf .container {
  padding: 0px!important;
}

.pieChartPer {
  margin-left: 12px;
}
.pieChartTitle {
  margin-left: 6;
}
.shadowBox {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.piechart_height {
  padding-top: 38px;
  width: 505px;
  height: 264px;
}
.answer-genius {
  margin-right: 0.6%;
}
.answer-genius-type {
  width: 37%;
}
.rating-lables {
  width: 15%;
}
.color-as-url {
  color: blue;
  cursor: pointer;
}
.feedback-card {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    z-index: auto;
}
.TableHeader {
  background-color: #016699;
}
.installment {
  max-height: 338px;
  overflow: auto;
}
@media (max-width: 767px) {
  .Circular-Progress {
    text-align: center;
    margin-top: 70%;
  }
}

.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: #000000 !important;
  text-decoration: none !important;
}

.tox-notifications-container {
  display: none !important;
}
.installment-count {
  color: black;
  background-color: #fff;
  /* background-color: #d3d3a2; */
  padding: 3px 8px 3px 8px;
  border-radius: 50%;
  border: 1px solid #dadada;
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 12px;
  margin-top: 5px;
}

.installment-more-option .remove-border {
  margin-top: 5px;
}

.show-more-text {
  color: #016699;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 4px;
  padding-left: 4px;
}

/* Apply vertical scrollbar and hide horizontal scrollbar */
.no-scroll-loan {
  overflow-y: scroll;
  overflow-x: hidden;
}

.no-scroll-loan::-webkit-scrollbar {
  width: 12px;
}

.no-scroll-loan::-webkit-scrollbar-thumb {
  background: #636262;
  border-radius: 10px;
}

.no-scroll-loan::-webkit-scrollbar-track {
  background: #eee;
}

/* Optional: For Firefox */
.no-scroll-loan {
  scrollbar-width: thin;
  scrollbar-color: #888 #eee;
}
.modal_close_button{
  border-radius: 50%;
  background-color: #016699;
  color: #eee;
  border: none;
  padding-bottom: 5px;
  margin-top: -8px;

}
.privacyPolicyIcon{
  width: 50px;
  height: 50px;
  /* color: #00000044; */
  color: #ff0000b2;
  cursor: pointer;
}
.LoanCardText p {
  margin-right: 18px;
  font-weight: 500 !important;
}
.cross-button-for-rollout{
  margin-top: -5px;
}
.title-rollout{
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 5px;
}
.subtitle-rollout{
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  color: #889;
  font-weight: 300;
}
.small-contained-button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.875em;
  text-transform: capitalize;
  font-family: "Public Sans", sans-serif;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  /* background-color: rgb(25, 97, 222); */
  background-color: #016699;
  box-shadow: rgb(25 97 222 / 24%) 0px 8px 16p;
}

.small-contained-button {
  border: 2px solid !important;
  padding: 8px 13px !important;
}
.table_header_action_cell{
  margin-left: 5px;
  margin-right: 10px;
  max-width: 80px;
 
}
.align_center{
  text-align: center;
}
.leave-report-filter-container{
overflow-x: hidden;
}

.product-image-width img{
  width: 55px!important;
  height: 50px!important;
  border-radius: 10px;
}
.cross_button_outtor{
position: relative;
}
.cross_button{
  border: 1px solid #212b36;
  border-radius: 50%;
  position: absolute;
  top: -28px;
  right: -10px;
  padding: 0px 7px;
  padding-bottom: 2px;
  color: #212b36;
}
.leave-report-filter{
  margin-left: 10px;
  margin-right: -15px;
}
.del_img_icon span {
  background-color: red;
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  position: absolute;
  right: -6px;
  text-align: center;
  top: -4px;
  width: 15px;
  z-index: 1;
}
.calculator-text{
  font-weight: 400;
}

.calculator-number{
  font-weight: 500;
}
.filter-cross-icon svg {
  height: 15px !important;
  width: 15px !important;

  /* fill: #fff!important; */
  /* fill: rgb(225 0 0)!important; */
  fill: #fff!important;
 
    /* background-color: rgb(225 0 0)!important; */
    background-color:  #fff!important;
    background-color:  #016699!important;
 
}
.filter-chip {
  font-size: 12px;
  border-radius: 15px;
  /* background-color: #016699!important; */
  background-color: #fff!important;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  /* line-height: 0px; */
  line-height: 1.5;
  white-space: nowrap;
  /* padding: 6px 8px; */
  padding: 6px 12px;
  border : 1px solid #c1c1c1;
  /* color: #fff!important; */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

 
.filter-chip:hover {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
}

.filter-chip:active {
  /* box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2) inset; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}
