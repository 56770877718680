@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --theme-selected-bg-color: rgb(235, 248, 242);
  --clr-green: #016699;
  --theme-primary-clr: #016699;
}
.theme-primary-txt {
  color: var(--theme-primary-clr);
}
.theme-primary-bg {
  background-color: var(--theme-primary-clr);
}
html,
body {
  scroll-behavior: auto !important;
}
.no-data-container {
  position: absolute;
  /* top: 40%; */
  margin-top: 10px;
  left: 50%;
  transform: translate(-50%, -40%);
}
.view-all-tasks-box {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 24px;
}
.highlight-task {
  transition: all 0.2s ease-in-out;
  outline: solid 3px #12ff89;
  border-radius: "3px";
}
.img-upload-btn {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: 0px;
  right: -7px;
  border: solid 1px rgba(128, 128, 128, 0.377);
}
.project-name-btn {
  color: #016699;
  /* color: rgb(0, 171, 85); */
  font-weight: 500;
  background-color: rgb(246, 247, 248);
  padding: 10px 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.task-date {
  color: #016699;
  /* color: rgb(0, 171, 85); */
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
}
.react-datepicker__time-container {
  width: 90px !important;
}
.react-datepicker__time-box {
  width: 90px !important;
}
.no-task-files {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.clr-green {
  color: var(--clr-green);
}
.role-bar {
  position: absolute;
  z-index: 3;
  border-radius: 0.3rem;
  bottom: 115px;
  right: 16px;
  cursor: pointer;
}
.task-card-menu-btn {
  position: absolute;
  top: 50%;
  right: 26px;
  transform: translateY(-50%);
  z-index: 2;
}
.vrf-task-card-menu-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 2;
}
.drag-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  z-index: 2;
}
.is-dragging {
  opacity: 40%;
  background-color: #7fc1e3;
}
.search-bar {
  position: sticky;
  top: 12px;
  background-color: white;
  z-index: 3;
  padding-top: 5px;
  padding-bottom: 0px;
}
.project-team-update-btn {
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

.project-header {
  border: 1px solid #c5c5c5 !important;
  border-radius: 10px !important;
  padding: 3px !important;
}
.project-header:active {
  border: 1px solid #c5c5c5 !important;
  border-radius: 10px !important;
}

.project-header-btn {
  box-shadow: none !important;
  background-color: #f6f7f8 !important;
  color: black !important;
  font-weight: 600;
}
.active-border {
  border: 1px solid #c5c5c5 !important;
}
.active-border:active {
  border: 1px solid #c5c5c5 !important;
}
.progress-box:active {
  border: 1px solid #c5c5c5 !important;
}
.task-type-btn {
  border: solid 1px rgba(1, 102, 153, 0.5) !important ;
  border-color: rgba(1, 102, 153 0.5) !important;
}
/* .task-type-btn {
  border: solid 1px rgba(0, 171, 85, 0.5) !important ;
  border-color: rgba(0, 171, 85, 0.5) !important;
} */
.task-type-btn:active {
  border: solid 1px rgba(1, 102, 153, 0.5) !important ;
  border-color: rgba(1, 102, 153, 0.5) !important;
}
/* .task-type-btn:active {
  border: solid 1px rgba(0, 171, 85, 0.5) !important ;
  border-color: rgba(0, 171, 85, 0.5) !important;
} */
.add-task-img-parent {
  position: relative;
  width: max-content;
}
.add-task-img {
  position: relative;
}
.add-task-img-remove {
  position: absolute;
  top: 0px;
  right: 0px;
}
.comment-loader-wrap {
  min-width: 100%;
  min-height: 30rem;
  position: relative;
}

.task-files-container {
  position: relative;
  /* display: flex; */
  /* align-items: center; */
}
.del-task-file-btn {
  top: 0px;
  position: absolute;
  right: 0px;
  z-index: 3;
}
.task-section-parent {
  border: 1px solid #c5c5c5 !important;
  border-radius: 5px;
  position: relative !important;
}
.task-section-parent .MuiAccordionSummary-expandIconWrapper {
  margin-right: 4px !important;
}
.task-section-parent:active {
  border: 1px solid #c5c5c5 !important;
}
/* #task-date-picker .MuiButtonBase-root MuiIconButton-root  MuiIconButton MuiButtonBase */
#task-date-picker .MuiButtonBase-root {
  padding: 0px !important;
}

.outline-btn {
  border: 1px solid #c5c5c5 !important;
}
.outline-btn:active {
  border: 1px solid #c5c5c5 !important;
}
.comment-option-bar {
  position: absolute;
  right: 3px;
}
/* mui overrides */

.avatar-container .MuiAvatarGroup-root .MuiAvatar-root {
  width: 29px;
  height: 29px;
  cursor: pointer;
}
/* utility classes */
.border {
  border: 1px solid #c5c5c5 !important;
}
.pointer {
  cursor: pointer !important;
}
.centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.centered-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pre-wrap {
  white-space: pre-wrap !important;
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.shadow-sm {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.shadow-md {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.shadow-lg {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}
.hidden {
  display: none !important;
}

/* fix Mui Table Pagination component margins issue by bootstrap */
.MuiTablePagination-root p {
  margin: 0px;
}
/* remove autofill colors on chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #000000 !important;
}
.react-datepicker__triangle {
  display: none !important;
}
